














































































































































import { Component, Mixins, Watch } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import ListDoubts from '@/components/Doubts/ListDoubts/ListDoubts.vue';
import Footer from '@/components/Footer/Footer.vue';
import Credits from '@/pages/Redaction/components/Credits/Credits.vue';
import Ranking from '@/components/Ranking/index.vue';

import LastSendDoubt from './components/LastSendDoubt/index.vue';

import RealtimeUsers from '@/widgets/Users/RealtimeUsers/RealtimeUsers.vue';

import Arrow from '@/assets/icons/Arrow.vue';

import BookService from '@/services/Book/BookService';

import {
  GetDoubts as IGetDoubts,
  GetMyDoubts as IGetMyDoubts,
} from '@/services/Doubts/DoubtsInterface';

import mixinDoubts from '@/mixins/doubts';
import CreditsConsumer from '@/mixins/CreditsConsumer';

import ActiveModal from '@/share/Util/ActiveModal';

import { PERMISSION_NAME_BY_DEGREE_ID } from '@/constant/PermissionBookByDegreeID';
import { LIST_PLAN_ID } from '@/constant/PlansIDs';

import { Book } from '@/globalInterfaces/Book';
import { Doubt as IDoubt } from '@/globalInterfaces/Doubts';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

const NUMBER_PAGE = 0;
const TAB_ACTIVE = 0;
const LIMIT_DOUBTS = 10;
const QUERY_MY_DOUBTS = {
  limit: LIMIT_DOUBTS,
  page: 0,
  logged: true,
};
const DEFAULT_FILL = 'var(--neutralcolor-low-pure)';

@Component({
  components: {
    PageHeader,
    BoxContainer,
    LastSendDoubt,
    ListDoubts,
    Arrow,
    Footer,
    Credits,
    RealtimeUsers,
    Ranking,
  },
})
export default class Doubts extends Mixins(mixinDoubts, CreditsConsumer) {
  private isLoadingTopics = false;
  private isLoadingDoubts = false;
  private isLoadingLastDoubts = true;

  private allDoubts: Array<IDoubt> = [];
  private myDoubts: Array<IDoubt> = [];
  private allBooks: Array<Book> = [];
  private topics: Array<Record<string, any>> = [];
  private topicDoubt: Array<Record<string, any>> = [];
  private lastDoubt: IDoubt | {} = {};

  private selectedBook = '';
  private selectedTopic = '';
  private quantityDoubtsForRequest = LIMIT_DOUBTS;

  private totalAllDoubts = 0;
  private totalMyDoubts = 0;

  private DEFAULT_FILL = DEFAULT_FILL;

  private BookService = new BookService();
  private ActiveModal = new ActiveModal();

  created() {
    this.getBooks();
  }

  mounted() {
    this.setBreadCrumbs();
    this.setQueryDoubts(NUMBER_PAGE, TAB_ACTIVE);
    this.getMyDoubts(QUERY_MY_DOUBTS);
  }

  get isDarkMode() {
    return this.$store.getters.isDarkMode;
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get setClassCol() {
    return this.hasPermission
      ? 'col-xl-9 col-md-8 col-12 order-2 order-md-1'
      : 'col';
  }

  get plan() {
    return this.$store.getters.profile?.plan;
  }

  get isPlanFree() {
    return this.plan?.planID === LIST_PLAN_ID.FREE;
  }

  @Watch('getTotalCreditsConsumed', {
    immediate: true,
  })
  activeModalConsumerCredits() {
    if (this.getTotalCreditsConsumed <= this.NOT_CREDIT_VALUE && this.isPlanFree) {
      this.ActiveModal.activeModal('ModalConsumerCredits');
    }
  }

  async getBooks() {
    try {
      const getAllBooks = await this.BookService.books(undefined, this.$store.getters.profile?.institution?.id);

      this.allBooks = this.filterBookPermission(getAllBooks);
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao recuperar os livros.',
        status: 'error',
      });
      console.error(error);
    }
  }

  filterBookPermission(books: Array<Book>) {
    return books.filter((book: Book) => this.can(PERMISSION_NAME_BY_DEGREE_ID[book?.degreeID]));
  }

  setQueryDoubts(page: number, tabActive: number) {
    const limit = 10;
    const isAllDoubts = tabActive === 0;

    let query: IGetDoubts | IGetMyDoubts = {};

    if (this.selectedBook && !this.selectedTopic) {
      query = {
        ...(limit && { limit }),
        ...((page || page >= 0) && { page }),
        ...(this.selectedBook && { disciplineID: [Number(this.selectedBook)] }),
        logged: true,
        orderby: 'desc',
      };
    } else if (this.selectedBook && this.selectedTopic) {
      query = {
        ...(limit && { limit }),
        ...((page || page >= 0) && { page }),
        ...(this.selectedBook && { disciplineID: [Number(this.selectedBook)] }),
        ...(this.selectedBook && { topicID: [Number(this.selectedTopic)] }),
        logged: true,
        orderby: 'desc',
      };
    } else {
      query = {
        ...(limit && { limit }),
        ...((page || page >= 0) && { page }),
        logged: true,
        orderby: 'desc',
      };
    }

    if (isAllDoubts) this.getAllDoubts(query);
    else this.getMyDoubts(query);
  }

  async getAllDoubts(query: IGetDoubts) {
    try {
      this.isLoadingDoubts = true;

      const { doubts, totalDoubts } = await this.DoubtsService.getDoubts(query);

      if (doubts && Object.entries(doubts).length) {
        this.totalAllDoubts = totalDoubts;

        this.allDoubts = [...this.allDoubts, ...doubts];
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar dúvidas.',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoadingDoubts = false;
    }
  }

  setChangeSelect(e: any) {
    this.selectedBook = e.target.value;

    const queryBook: IGetDoubts | IGetMyDoubts = {
      ...(this.selectedBook && { disciplineID: [Number(this.selectedBook)] }),
    };

    this.getDoubtsBook(queryBook);

    // this.isLoadingTopics = true;

    this.getBookTopics(this.selectedBook);
  }

  async getDoubtsBook(queryBook: IGetDoubts | IGetMyDoubts) {
    try {
      this.isLoadingDoubts = true;

      const limit = 10;

      const queryAllDoubts: IGetDoubts | IGetMyDoubts = {
        ...queryBook,
        ...(limit && { limit }),
        ...(NUMBER_PAGE >= 0 && { page: NUMBER_PAGE }),
        logged: true,
      };

      const allDoubts = await this.DoubtsService.getDoubts(queryAllDoubts);
      const myDoubts = await this.DoubtsService.getMyDoubts(queryAllDoubts);

      this.allDoubts = allDoubts?.doubts || [];
      this.myDoubts = myDoubts?.doubts || [];
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar as dúvidas.',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoadingDoubts = false;
    }
  }

  async getBookTopics(idBook: string) {
    try {
      this.isLoadingTopics = true;

      if (!idBook) return;

      const topics = await this.BookService.getTopicsBook(Number(idBook));

      if (topics.length) {
        this.topics = topics;
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao recuperar a lista de tópicos.',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoadingTopics = false;
    }
  }

  async setChangeSelectTopic(e: any) {
    try {
      this.isLoadingDoubts = true;

      this.selectedTopic = e.target.value;

      const queryAllDoubts: IGetDoubts | IGetMyDoubts = {
        ...(LIMIT_DOUBTS >= 0 && { limit: LIMIT_DOUBTS }),
        ...(NUMBER_PAGE >= 0 && { page: NUMBER_PAGE }),
        ...(this.selectedTopic && { topicID: [Number(this.selectedTopic)] }),
        ...(this.selectedBook && { disciplineID: [Number(this.selectedBook)] }),
        logged: true,
      };

      const allDoubts = await this.DoubtsService.getDoubts(queryAllDoubts);
      const myDoubts = await this.DoubtsService.getMyDoubts(queryAllDoubts);

      this.allDoubts = allDoubts?.doubts || [];
      this.myDoubts = myDoubts?.doubts || [];
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar as dúvidas.',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoadingDoubts = false;
    }
  }

  async getMyDoubts(query: IGetMyDoubts) {
    try {
      this.isLoadingLastDoubts = true;

      const { doubts, totalDoubts } = await this.DoubtsService.getMyDoubts(query);

      if (doubts && doubts.length) {
        this.totalMyDoubts = totalDoubts;

        this.myDoubts = [...this.myDoubts, ...doubts];

        this.lastDoubt = Object.entries(this.lastDoubt).length ? this.lastDoubt : this.myDoubts[0];
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar suas dúvidas.',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoadingLastDoubts = false;
    }
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Dúvidas', to: null },
    ]);
  }

  redirect() {
    if (!this.getRemainingCreditsDoubts) return;

    this.setTrackAmplitude();

    this.$router.push({ name: 'NewDoubt' });
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'Doubts',
          local: 'Enviar uma nova dúvida',
        },
      },
    });
  }
}
